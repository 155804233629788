@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body {
	background: #EDE9E7;
	font-family: 'Poppins', sans-serif;
}
.tituloslider{
	
	font-size: 30px;
	
}

main {
	max-width: 1500px;
	margin: 71px auto;
	overflow: hidden;
	
	position: relative;
	
	
}
 	


/*.slider {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	position: relative;
    margin-top: 71px;
}
.slider__title {
	position: absolute;
	width: 100%;
	text-align: center;
	top: 1.2rem;
	color: rgb(17, 135, 239);
	text-transform: uppercase;
    font-size: 55px;
	font-weight: bold;
	font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
	padding: 0 4rem;
	z-index: 100;
	text-shadow: 0 0 0.5rem rgb(12, 151, 232);
}
.slider__content,
.slider__content--item {
	width: 100%;
	height: 100vh;
	position: relative;
}

.slider__content--item img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.slider-description {
	position: absolute;
	bottom: 5rem;
	width: 100%;
	text-align: center;
	font-size: 2.5rem;
	font-weight: bold;
	padding: 1rem 4rem;
	background-color: rgba(255, 85, 85, 0.5);
}*/